import styled from "@emotion/styled";
import { Header } from "../../compontents/Header";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import Pagination from "react-js-pagination";
import SideModal from "../../compontents/SideModal";
import Modal from "../../compontents/Modal";
import AttendanceCalendar from "../../compontents/AttendanceCalendar";
import dayjs from "dayjs";
import { ModalButton } from "../../compontents/ModalButton";
import { PagingFooter } from "../../compontents/PagingFooter";
import LaborEmployPagePrint from "../../compontents/print/LaborEmployPrint";
import { useReactToPrint } from "react-to-print";
import axiosInstance from "../../library/axios-index";
import {
  addCommas,
  BlockScrolling,
  checkRole,
  idNumToCurrentOld,
  numberOnly,
  stringNumberToInt,
} from "../../bin/common";
import DownLoadExcel from "../../library/handle-excel";
import AxiosNavigate from "../../library/axios-navigate";
import { useCookies } from "react-cookie";
import { RoleType } from "../../bin/enum";
import { useLocation } from "react-router-dom";
import SearchAddress from "../../compontents/SearchAddress";
import ImageUploader from "react-image-upload";
import axios from "axios";
import { HOST_URL } from "../../bin/env";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import {
  createApplyJob,
  getContractPrint,
  getLaborContract,
  modifyCancelLeaveJob,
  planData,
} from "../../library/axios-query";
import PagesList from "../../compontents/PagesList";
import SelectedFilter from "../../compontents/SelectedFilter";
import SearchBox from "../../compontents/Search";
import EmptyContainer from "../../compontents/EmptyContainer";
import Loading from "../../compontents/Loading";
import { InputBox } from "../../library/styled-components";
import EmployIdCheckForm from "./employ-Id-check-form";
import { getLaborInfo } from "../../library/admin-react-query";
import SelectedFilterBox from "../../compontents/SelectedFilterBox";
import ContractBizStandardPrint from "../../compontents/print/ContractBizStandardPrint";
import LaborContractPagePrint from "../../compontents/print/LaborContractPrint";
import ContractBizConstructPrint from "../../compontents/print/ContractBizConstructPrint";
import RoleEmployForm from "../setting/role-employ-form";
import moment from "moment";
import UserPaymentStatement from "./employ-user-payment-statement";
import TableModal from "../../compontents/TableModal";

const selectStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #E2E8F0",
    },
  }),
};

const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const sortOptions = [
  { value: "1", label: "이름" },
  { value: "2", label: "나이" },
  { value: "3", label: "현장명" },
  { value: "4", label: "입사일" },
  { value: "5", label: "직종" },
  { value: "6", label: "소속" },
];

const filterOptions = [
  { value: "1", label: "전체 근로자" },
  { value: "2", label: "근무중" },
  { value: "3", label: "퇴사" },
  { value: "4", label: "입사승인대기" },
];

const salaryOption = [
  { value: "D", label: "일급" },
  { value: "M", label: "월급" },
  { value: "C", label: "본사협의(월급)" },
];

const relateOptions = [
  { value: "본인", label: "본인" },
  { value: "가족", label: "가족" },
  { value: "친척", label: "친척" },
  { value: "기타", label: "기타" },
];

const bloodType = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "O", label: "O" },
  { value: "AB", label: "AB" },
];

const idCardOptions = [
  { value: "ic", label: "주민등록증" },
  { value: "dl", label: "운전면허증" },
  { value: "ac", label: "외국인등록증" },
];

const monthSalaryTypeOptions = [
  { value: "당월", label: "당월" },
  { value: "익월", label: "익월" },
];
const monthSalaryOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
];
let selectEmploy = undefined;

function LaborEmploy() {
  const formRef = useRef();
  const closeModal = useRef(false);
  const userModal = useRef();
  const location = useLocation();
  const [cookies] = useCookies(["role"]);
  const [loading, setLoading] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(options[0].value);
  const [searchTxt, setSearchTxt] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("2");
  const [siteFilter, setSiteFilter] = useState("");
  const [siteFilterOption, setSiteFilterOption] = useState([]);
  const [employWaiting, setEmployWaiting] = useState(false);

  const [nations, setNations] = useState({});
  const [jobPosition, setJobPosition] = useState({});
  const [position, setPosition] = useState({});
  const [rank, setRank] = useState({});

  const [selectedEmploy, setSelectedEmploy] = useState(undefined);
  const [positionOptions, setPositionOptions] = useState([]);
  const [jobPositionOptions, setJobPositionOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [selectedJobPositionOption, setSelectedJobPositionOption] = useState(
    []
  );
  const [rankOptions, setRankOptions] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);
  const [siteClassOptions, setSiteClassOptions] = useState([]);
  const [selectPartnerType, setSelectPartnerType] = useState(undefined);
  const [selectPartnerNm, setSelectPartnerNm] = useState("");
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [companyNm, setCompanyNm] = useState("");
  const [leaveRadioOption, setLeaveRadioOption] = useState("Y");
  const [selectLeaveDt, setSelectLeaveDt] = useState(
    dayjs(Date()).format("YYYY-MM-DD")
  );
  const [selectJoinedDt, setSelectJoinedDt] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectLeavedDt, setSelectLeavedDt] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );

  const [opendDetailEmploy, setOpendDetailEmploy] = useState(false);
  const [opendModifyEmploy, setOpendModifyEmploy] = useState(false);
  const [opendExport, setOpendExport] = useState(false);
  const [radioOption, setRadioOption] = useState("I");
  const [opendLaborContract, setOpendLaborContract] = useState(false);
  const [opendUserPayment, setOpendUserPayment] = useState(false);

  const [print, setPrint] = useState(false);
  const [modalEmployYN, setModalEmployYN] = useState(false); // false => 생성 , true => 수정
  //   const [userSeq, setUserSeq] = useState(null);

  const { register, control, handleSubmit, setValue, watch, reset, getValues } =
    useForm({
      defaultValues: {
        labor_img: "",
        name: "",
        nation: "",
        bloodType: "I",
        phone: "",
        zoneCode: "",
        address: "",
        address2: "",
        latitude: "",
        logitude: "",
        idType: "",
        idNum: "",
        foreignId: "",
        bank: "",
        account: "",
        accountOwner: "",
        relate: "",
        siteNew: "",
        joinDate: dayjs(Date()).format("YYYY-MM-DD"),
        salaryType: "",
        salaryAmt: "0",
        jobPosition: "",
        roleNew: "",
        rankNew: "",
        class: "",
        partner: "",
        idcard_img: "",
        bank_img: "",
        user_seq: "",
      },
    });

  const [opendNewEmploy, setOpendNewEmploy] = useState(false);
  const [openedIdCheck, setOpenedIdCheck] = useState(false);
  const [opendRoleEmploy, setOpendRoleEmploy] = useState(false);

  const [nationOption, setNationOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [siteOption, setSiteOption] = useState([]);

  BlockScrolling(opendNewEmploy);
  BlockScrolling(opendDetailEmploy);
  BlockScrolling(opendModifyEmploy);
  BlockScrolling(opendExport);
  BlockScrolling(opendLaborContract);

  const siteNewWatch = watch("siteNew");

  //근로자 표준 계약서 조회 api
  const { mutate: planDataMutate, data: planDataData } = useMutation({
    mutationKey: ["planData"],
    mutationFn: planData,
    onSuccess: (data) => {},
    onError: (error) => {
      throw error;
    },
  });

  //근로자 표준 계약서 조회 api
  const { mutate: getLaborContractMutate, data: getLaborContractData } =
    useMutation({
      mutationKey: ["getLaborContract"],
      mutationFn: getLaborContract,
      onSuccess: (data) => {
        setOpendLaborContract(true);
      },
      onError: (error) => {
        throw error;
      },
    });

  // 근로자 입사 신청 승인 처리
  const { mutate: createApplyJobMutate } = useMutation({
    mutationKey: ["createApplyJob"],
    mutationFn: createApplyJob,
    onSuccess: async (data) => {
      if (data.done) {
        alert("입사 승인이 되었습니다.");
        setOpendModifyEmploy(false);
        setOpendDetailEmploy(false);
        setSelectedEmploy(undefined);
        await loadEmployees();
        selectEmploy.loading = false;
      }
    },
    onError: (error) => {
      // console.log(error);
      if (error.response.data.code === 400) {
        alert(error.response.data.message);
        return;
      }
      throw error;
    },
  });
  //근로자 근로계약서 출력 시 필요한 데이터 제공 API
  const { mutate: getContractPrintMutate, data: getContractPrintData } =
    useMutation({
      mutationKey: ["getContractPrint"],
      mutationFn: getContractPrint,
      onSuccess: (data) => {},
      onError: (error) => {
        console.log(error);
        throw error;
      },
    });

  // 근로자 퇴사 취소 처리(Admin Web용)
  const { mutate: cancelLeaveMutate } = useMutation({
    mutationKey: ["modifyCancelLeaveJob"],
    mutationFn: modifyCancelLeaveJob,
    onSuccess: async (data) => {
      if (data.done) {
        setOpendDetailEmploy(false);
        setSelectedEmploy(undefined);
        await loadEmployees();
      }
    },
    onError: (error) => {
      if (error.response.data.code === 400) {
        alert(error.response.data.message);
        return;
      }
      throw error;
    },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onPrintError: (error) => console.error("프린트 오류:", error),
  });

  // 근로자정보 - 주민번호, 회원순번으로 검색
  const { mutate: getLaborInfoMutate, data: getLaborInfoData } = useMutation({
    mutationKey: "getLaborInfo",
    mutationFn: getLaborInfo,
    onSuccess: (data) => {
      setValue("name", data?.user_nm);
      setValue("phone", data?.phone_num);
      setValue("account", data?.bank_acct);
      setValue("accountOwner", data?.bank_user_nm);
      setValue("relate", data?.bank_user_rel);
      setValue("address", data?.addr1);
      setValue("address2", data?.addr2);
      setValue("bank_img", data?.bank_photo);
      setValue("idcard_img", data?.id_photo);
      setValue("labor_img", data?.photo);
      setValue("bloodType", data?.blood_type);
      setValue("nation", data?.nationality);
      setValue("idType", data?.id_type);
      setValue("bank", data?.bank_nm);
      setValue("latitude", data?.latitude);
      setValue("logitude", data?.logitude);
      setValue("zoneCode", data?.postal_cd);
      setValue("user_seq", data?.user_seq);
      setValue("foreignId", data?.stay_cd === null ? "" : data?.stay_cd);
      //  id_type: e.idType,
    },
    onError: (error) => {
      throw error;
    },
  });

  // 근로 계약서 프린트 작업
  const contractRef = useRef();
  const handlePrint2 = useReactToPrint({
    content: () => contractRef.current,
    documentTitle:
      getContractPrintData && `근로계약서_${getContractPrintData.userName}`,
    onPrintError: (error) => console.error("프린트 오류2:", error),
  });

  // 엑셀 다운로드
  const handleExcel = () => {
    if (employees.length < 1) {
      alert("근로자가 없습니다.");
      return;
    }

    DownLoadExcel({
      txt: "근로자관리",
      date: dayjs().format("YYYY-MM-DD"),
      type: 1,
    });
  };

  const handleOpenPrintModal = useCallback(() => {
    setOpendExport(true);
  }, []);

  // selectedEmploy load
  const handleEmployInfo = async (user_site_seq) => {
    const token = localStorage.getItem("admin_token");

    let siteClass = [];
    let partner = [];

    const {
      data: {
        result: { employ },
      },
    } = await axiosInstance.post(`/admin/laborEmployInfo`, {
      lang: "ko",
      uuid: "string",
      token,
      user_site_seq,
    });

    await siteClassRequest(employ.site_seq);

    if (employ.belong_type == "O") {
      await partnerListRequest(employ.site_seq, employ.partner_type);
      // setPartnerOptions(partner)
    }

    setSelectPartnerNm(
      partner.find((e) => e.value === employ.partner_seq)?.label ?? ""
    );

    const employ2 = {
      ...employ,
      salary_nm:
        salaryOption.find((e) => e.value === employ.salary_type)?.label ?? "",
      position_nm:
        positionOptions.find((e) => e.value === employ.role_nm)?.label ?? "",
      job_position_nm:
        jobOptions.find((e) => e.value === employ.job_position)?.label ?? "",
      // rank_nm: rankOptions.find((e) => e.value === employ.rank_nm)?.label ?? "",
      partner_type_nm:
        partnerTypeOptions.find((e) => e.value === employ.partner_type)
          ?.label ?? "",
      site_class_nm:
        employ.belong_type === "I"
          ? siteClass.find((e) => e.value === employ.class_seq)?.label ?? ""
          : "",
    };

    setSelectLeaveDt(dayjs(Date()).format("YYYY-MM-DD"));
    // setSelectLeaveDt(moment(Date()).format('YYYY-MM-DD'));
    setSelectedEmploy(employ2);
    setSelectLeavedDt(
      !employ2.end_dt
        ? moment(new Date()).add(1, "days").format("YYYY-MM-DD")
        : employ2.end_dt
    );
    setLeaveRadioOption(!employ2.end_dt ? "N" : "Y");
    handleSelectedJobPositionOption(employ2.work_field);
    setOpendDetailEmploy(true);
    setRadioOption(employ2.belong_type);
    setSelectPartnerType(employ2.partner_type);
  };

  const siteClassRequest = async (site_seq) => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/constSiteClassList`, {
      lang: "ko",
      uuid: "string",
      token,
      site_seq,
    });

    setSiteClassOptions(
      result.map((e) => {
        return { value: e.class_seq, label: e.class_nm };
      })
    );
  };

  const partnerListRequest = async (site_seq, partner_type) => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/partnerList`, {
      lang: "ko",
      uuid: "string",
      token,
      site_seq,
      partner_type,
    });

    setPartnerOptions(
      result.map((e) => {
        return { value: e.partner_seq, label: e.cmpny_nm };
      })
    );
  };
  // 근로자 수정 api
  const modifyEmploy = async (data) => {
    if (data.role_nm !== selectedEmploy?.role_nm) {
      if (data.role_nm === "M0001D0002") {
        alert(
          "현장소장 임명은 '현장관리 > 상세보기 > 수정하기'에서 등록해 주세요"
        );
        return;
      }
    }

    //   return;
    if (radioOption === "I" && (!data.class_seq || data.class_seq === 0)) {
      alert("소속을 선택해주세요.");
      return;
    }

    if (radioOption === "O" && (!data.partner_seq || data.partner_seq === 0)) {
      alert("협력사를 선택해주세요.");
      return;
    }

    const token = localStorage.getItem("admin_token");

    const request = {
      lang: "ko",
      uuid: "string",
      token,
      user_site_seq: selectedEmploy.user_site_seq,
      user_seq: selectedEmploy.user_seq,
      site_seq: selectedEmploy.site_seq,
      salary_type: selectedEmploy.salary_type,
      salary_amt: data.salary_amt,
      role_nm: data.role_nm,
      rank_nm: data.rank_nm,
      job_position: data.job_position,
      belong_type: radioOption,
      class_seq: radioOption === "I" ? data.class_seq : 0,
      partner_seq: radioOption === "O" ? data.partner_seq : 0,
      end_dt: leaveRadioOption === "N" ? null : selectLeavedDt,
      salary_month_type: data.salary_month_type === "익월" ? "M1" : "M0",
      salary_day: data.salary_day,
    };

    try {
      const {
        data: { result },
      } = await axiosInstance.post(`/admin/laborEmployModify`, request);

      if (result.done === true) {
        alert("수정되었습니다.");
        setOpendModifyEmploy(false);
        setOpendDetailEmploy(false);
        setSelectedEmploy(undefined);
        await loadEmployees();
        selectEmploy.loading = false;
      } else {
        alert("수정에 실패하였습니다.");
      }
    } catch (error) {
      alert(`${error.response.data.message}`);
      //  throw error;
    }
  };

  // 협력사 선택옵션
  const loadSelectPartnerType = async (e) => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/partnerList`, {
      lang: "ko",
      uuid: "string",
      token,
      site_seq: e.site_seq,
      partner_type: selectPartnerType,
    });

    setPartnerOptions(
      result.map((e) => {
        return { value: e.partner_seq, label: e.cmpny_nm };
      })
    );
  };

  // 라디오 버튼
  const handleRadio = (e) => {
    setRadioOption(e.target.value);
  };

  // 리스트 로드
  const loadEmployees = async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: {
        result: { laborEmployees, totalCount },
      },
    } = await axiosInstance.post(`/admin/laborEmployList`, {
      lang: "ko",
      uuid: "string",
      token,
      search_txt: searchTxt.replaceAll(" ", ""),
      page,
      limit,
      sort,
      filter,
      siteFilter,
    });

    setEmployees(laborEmployees);
    setTotalCount(totalCount);
    return laborEmployees;
  };

  // 데이터 로드
  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("admin_token");

      const nationResp = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0009",
      });

      const bankResp = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0010",
      });

      const positionResp = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0001",
      });

      const rankResp = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0002",
      });

      const supportResp = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0005",
      });

      const {
        data: {
          result: { sites, totalCount },
        },
      } = await axiosInstance.post(`/admin/siteList`, {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: "",
        page: 1,
        limit: 1000,
        filter: "A",
      });

      // 공종 중복 제거
      const sitesWorkField = Array.from(
        new Set(
          sites.map((site) => {
            return site.work_field;
          })
        )
      );

      let jobPositionResp = [];

      //공통 직종 가져오기
      const commonJobPosition = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0004",
        attribute1: "*",
      });

      jobPositionResp.push(...commonJobPosition.data.result);

      // 공종을 통해 직종 가져오기
      // for (let i = 0; i < sitesWorkField.length; i++) {
      //     const siteJobPosition = await axiosInstance.post(`/code/codeList`, {
      //         lang: 'ko',
      //         uuid: 'string',
      //         token,
      //         mst_cd_id: 'M0004',
      //         attribute1: sitesWorkField[i],
      //     });
      //     jobPositionResp.push(...siteJobPosition.data.result);
      // }
      //

      jobPositionResp = Array.from(
        new Map(
          jobPositionResp.map((jobPosition) => [
            jobPosition.dtl_cd_id,
            jobPosition,
          ])
        ).values()
      );

      const siteFilter = new Map(
        sites.map((site) => [site.site_seq, site.site_nm])
      );

      const filterOption = [];

      siteFilter.forEach((value, key) => {
        filterOption.push({ value: "" + key, label: value });
      });

      filterOption.sort((a, b) => a.label.localeCompare(b.label));
      filterOption.unshift({ value: "", label: "전체 현장" });

      setSiteFilterOption(filterOption);

      setNations(
        nationResp.data.result.reduce((acc, cur) => {
          acc[`${cur.dtl_cd_id}`] = cur.dtl_cd_nm;

          return acc;
        }, {})
      );

      setNationOption(
        nationResp.data.result.map((e) => {
          return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
        })
      );

      setBankOption(
        bankResp.data.result.map((e) => {
          return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
        })
      );

      setRoleOption(
        positionResp.data.result.map((e) => {
          return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
        })
      );

      //setSiteOption
      //(
      let testFilter = [];
      sites.map((e) => {
        if (e.end_yn === "N") {
          testFilter.push({
            value: e.site_seq,
            label: e.site_nm,
            field: e.work_field,
          });
        }
      });

      setSiteOption(testFilter);
      setJobPosition(
        jobPositionResp.reduce((acc, cur) => {
          acc[`${cur.dtl_cd_id}`] = cur.dtl_cd_nm;
          return acc;
        }, {})
      );

      setPosition(
        positionResp.data.result.reduce((acc, cur) => {
          acc[`${cur.dtl_cd_id}`] = cur.dtl_cd_nm;

          return acc;
        }, {})
      );

      setRank(
        rankResp.data.result.reduce((acc, cur) => {
          acc[`${cur.dtl_cd_id}`] = cur.dtl_cd_nm;

          return acc;
        }, {})
      );

      setJobOptions(
        jobPositionResp.map((e) => {
          return {
            value: e.dtl_cd_id,
            label: e.dtl_cd_nm,
            workField: e.attribute1,
          };
        })
      );

      setPositionOptions(
        positionResp.data.result.map((e) => {
          return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
        })
      );

      setRankOptions(
        rankResp.data.result.map((e) => {
          return { value: e.dtl_cd_id, label: e.dtl_cd_nm };
        })
      );

      setPartnerTypeOptions(
        supportResp.data.result.map((e) => {
          return { value: e.attribute1, label: e.dtl_cd_nm };
        })
      );
    })();
    planDataMutate("M0013");

    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  const handleLeaveJob = async (employ) => {
    if (!checkRole(location.pathname, cookies.role, RoleType.WRITE)) {
      return;
    }

    const answer = window.confirm(
      `${employ.user_nm}님을 퇴사 처리 하시겠습니까?\n`
    );
    if (answer) {
      //some code
      try {
        const token = localStorage.getItem("admin_token");
        const result = await axiosInstance.post(`/admin/leaveJob`, {
          token,
          site_seq: employ.site_seq,
          user_seq: employ.user_seq,
          user_site_seq: employ.user_site_seq,
          leave_dt: selectLeaveDt,
        });
        await handleEmployInfo(employ.user_site_seq);
        await loadEmployees();
        alert("퇴사처리 되었습니다");
      } catch (e) {
        alert(e.response.data.message);
      }
    }
    return;
  };

  // 근로계약서 팝업 오픈
  const handleLaborContract = useCallback((site_seq) => {
    getContractPrintMutate({ userSiteSeq: site_seq });
    getLaborContractMutate(site_seq);
  }, []);

  const handleSetValue = (name, v) => {
    setValue(name, v);
  };

  const handleAddrAndZoneCode = (fullAddr, zoneCode = "") => {
    setValue("address", fullAddr);
    setValue("zoneCode", zoneCode);
  };

  const setLongitudeAndLatitude = (longitude, latitude) => {
    setValue("logitude", longitude);
    setValue("latitude", latitude);
  };

  const handleImageRemove = (name) => {
    setValue(name, "");
  };

  const handleImageUpload = async (e, name) => {
    const formData = new FormData();
    formData.append("files", e.file);

    const {
      data: { result },
    } = await axios({
      method: "post",
      url: `${HOST_URL}/upload`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const img = result[0];
    setValue(name, img);
  };

  const handleNewEmploySubmit = async (e) => {
    if (e.name === "") {
      alert("이름을 입력해주세요.");
      return;
    }

    if (e.nation === "") {
      alert("국적을 선택해주세요.");
      return;
    }
    if (e.bloodType === "I") {
      alert("혈액형을 선택해주세요.");
      return;
    }
    if (e.phone === "") {
      alert("휴대전화를 입력해주세요.");
      return;
    }
    if (e.idType === "") {
      alert("신분증 종류를 선택해주세요.");
      return;
    }
    if (e.idNum === "") {
      alert("주민등록번호를 입력해주세요.");
      return;
    }

    if (e.bank === "") {
      alert("은행를 선택해주세요.");
      return;
    }
    if (e.account === "") {
      alert("계좌번호를 입력해주세요.");
      return;
    }
    if (e.accountOwner === "") {
      alert("예금주를 입력해주세요.");
      return;
    }
    if (e.relate === "") {
      alert("예금주의 관계를 선택해주세요.");
      return;
    }

    if (e.address === "") {
      alert("주소를 입력해주세요.");
      return;
    }
    if (e.address2 === "") {
      alert("상세주소를 입력해주세요.");
      return;
    }
    if (e.siteNew === "") {
      alert("현장를 선택해주세요.");
      return;
    }
    if (e.salaryType === "") {
      alert("급여 종류를 선택해주세요.");
      return;
    }
    if (e.salaryAmt === "0") {
      alert("급여를 입력해주세요.");
      return;
    }

    if (e.jobPosition === "") {
      alert("직종를 선택해주세요.");
      return;
    }
    if (e.roleNew === "") {
      alert("역할을 선택해주세요.");
      return;
    }
    if (e.rankNew === "") {
      alert("직급을 선택해주세요.");
      return;
    }

    if (e.idType === "ac" && e.foreignId === "") {
      alert("체류자격을 입력해주세요.");
      return;
    }

    if (radioOption === "I" && (!e.class || e.class === "")) {
      alert("소속을 선택해주세요.");
      return;
    }

    if (radioOption === "O" && (!e.partner || e.partner === "")) {
      alert("협력사를 선택해주세요.");
      return;
    }

    if (e.idcard_img === "" || e.bank_img === "") {
      alert("신분증과 통장사본은 필수입니다");
      return;
    }

    const request = {
      lang: "ko",
      uuid: "string",
      photo: e.labor_img,
      user_nm: e.name,
      nationality: e.nation,
      blood_type: e.bloodType,
      phone_num: e.phone,
      postal_cd: e.zoneCode,
      addr1: e.address,
      addr2: e.address2,
      latitude: e.latitude,
      logitude: e.logitude,
      id_type: e.idType,
      id_num: e.idNum,
      id_photo: e.idcard_img,
      bank_nm: e.bank,
      bank_acct: e.account,
      bank_user_nm: e.accountOwner,
      bank_user_rel: e.relate,
      bank_photo: e.bank_img,
      site_seq: e.siteNew,
      join_dt: e.joinDate,
      salary_type: e.salaryType,
      salary_amt: stringNumberToInt(e.salaryAmt),
      job_position: e.jobPosition,
      role_nm: e.roleNew,
      rank_nm: e.rankNew,
      belong_type: radioOption,
      class_seq: radioOption === "I" ? e.class : null,
      partner_seq: radioOption === "O" ? e.partner : null,
      //체루 자격
      stay_cd: e.foreignId === "" ? "" : e.foreignId,
      create_type: modalEmployYN ? "U" : "I",
      user_seq: e.user_seq === "" ? null : e.user_seq,
    };
    // return;
    try {
      const {
        data: { result },
      } = await axiosInstance.post(`/admin/laborEmployNew`, request);

      if (result.done === true) {
        alert("등록되었습니다.");
        setOpendNewEmploy(false);
        reset();
        // setValue('joinDate', dayjs(Date()).format('YYYY-MM-DD'));
        // setValue('bloodType', 'I');
        await loadEmployees();
        reset();
      } else {
        alert("등록에 실패하였습니다.");
      }
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  // 리스트 필터
  useEffect(() => {
    (async () => {
      try {
        await loadEmployees();
      } catch (e) {}
    })();
  }, [searchTxt, page, limit, sort, filter, siteFilter]);

  // 협력사 선택
  useEffect(() => {
    if (!selectedEmploy || !selectEmploy) return;
    (async () => {
      await loadSelectPartnerType(selectedEmploy);
    })();
  }, [selectPartnerType]);

  const getCompany = async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/company/verifyUser`, {
      lang: "ko",
      uuid: "string",
      token,
    });
    setCompanyNm(result.corp_nm);
  };

  useEffect(() => {
    getCompany();
  }, []);

  const handleSelectedJobPositionOption = (workField) => {
    const option = jobOptions.filter(
      (option) =>
        option.workField === workField || option.workField === "M0003D1000"
    );
    setSelectedJobPositionOption(option);
  };

  const handleCheckId = (type, data) => {
    if (type === 1) {
      // 추가
      //   setUserSeq(null);
      setOpendNewEmploy(true);
      setModalEmployYN(false);
      setRadioOption("I");
    } else if (type === 2) {
      // 수정
      //   setUserSeq(data?.user_seq);
      setModalEmployYN(true);
      getLaborInfoMutate(data);
      setOpendNewEmploy(true);
    }

    setValue("idNum", data?.id_num);
    setOpenedIdCheck(false);
  };

  const selectedList = useCallback((work_field) => {
    (async () => {
      const token = localStorage.getItem("admin_token");
      const {
        data: { result },
      } = await axiosInstance.post(`/code/codeList`, {
        lang: "ko",
        uuid: "string",
        token,
        mst_cd_id: "M0004",
        attribute1: work_field,
      });

      setJobPosition(
        result.reduce((acc, cur) => {
          acc[`${cur.dtl_cd_id}`] = cur.dtl_cd_nm;
          return acc;
        }, {})
      );
      setJobPositionOptions(
        result.map((e) => {
          return {
            value: e.dtl_cd_id,
            label: e.dtl_cd_nm,
            workField: e.attribute1,
          };
        })
      );
    })();
  }, []);

  // 입사 승인
  const handleApplyJob = (joinApply) => {
    if (
      radioOption === "I" &&
      (!selectEmploy.class_seq || selectEmploy.class_seq === 0)
    ) {
      alert("소속을 선택해주세요.");
      return;
    }

    if (
      radioOption === "O" &&
      (!selectEmploy.partner_seq || selectEmploy.partner_seq === 0)
    ) {
      alert("협력사를 선택해주세요.");
      return;
    }

    const items = {
      siteSeq: selectEmploy.site_seq,
      userSeq: selectEmploy.user_seq,
      belongType: radioOption,
      classSeq: radioOption === "I" ? selectEmploy.class_seq : null,
      partnerSeq: radioOption === "O" ? selectEmploy.partner_seq : null,
      jobPosition: selectEmploy.job_position,
      roleNm: selectEmploy.role_nm,
      rankNm: selectEmploy.rank_nm,
      salaryType: selectedEmploy.salary_type,
      salaryAmt: selectEmploy.salary_amt,
      joinDt: selectJoinedDt,
      endDt: leaveRadioOption === "N" ? null : selectLeavedDt,
      salaryMonthType: selectEmploy.salary_month_type === "익월" ? "M1" : "M0",
      salaryDay: selectEmploy.salary_day,
      joinApprYn: joinApply,
    };

    createApplyJobMutate(items);
  };

  useEffect(() => {
    if (siteNewWatch === "") return;
    setValue("jobPosition", "");
    setValue("class", "");
    setValue("partnerType", "");
    setValue("partner", "");
    setPartnerOptions([]);
  }, [siteNewWatch, setValue]);

  return (
    <AxiosNavigate>
      <div>
        <Header>
          <div style={{ whiteSpace: "nowrap" }}>근로자 관리</div>
          <Panel>
            <div></div>
            <div>
              <ButtonGroup>
                <SearchBox
                  style={{ height: "38px" }}
                  placeholder="직원 검색"
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      e.nativeEvent.isComposing === false
                    ) {
                      if (
                        e.target.value.length > 0 &&
                        e.target.value.trim().length === 0
                      ) {
                        alert("직원을 입력해 주세요");
                        setSearchValue(e.target.value.trim());
                        return;
                      }
                      setSearchValue(e.target.value.trim());
                      setSearchTxt(e.target.value);
                    }
                  }}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                ></SearchBox>
                <Button
                  style={{ backgroundColor: "#3279F5", color: "#fff" }}
                  onClick={() => {
                    if (
                      checkRole(location.pathname, cookies.role, RoleType.WRITE)
                    ) {
                      setOpenedIdCheck(true);
                    }
                  }}
                >
                  <Icon src="/assets/icons/plus.svg" /> 신규등록
                </Button>
                <Button onClick={handleExcel}>
                  <Icon src="/assets/icons/excel.svg" /> 엑셀파일
                </Button>
                <Button onClick={handleOpenPrintModal}>
                  <Icon src="/assets/icons/printer.svg" /> 출력하기
                </Button>
                <SelectedFilter
                  options={sortOptions}
                  placeholder={"정렬"}
                  iconName={"menu-02.svg"}
                  style={{
                    width: "125px",
                    height: "38px",
                    border: "1px solid #E2E8F0",
                  }}
                  onChange={(e) => {
                    setPage(1);
                    setSort(e.value);
                  }}
                ></SelectedFilter>

                <SelectedFilter
                  options={filterOptions}
                  placeholder={filterOptions[1].label}
                  style={{
                    width: "160px",
                    height: "38px",
                    border: "1px solid #E2E8F0",
                  }}
                  onChange={(e) => {
                    setPage(1);
                    setFilter(e.value);
                  }}
                ></SelectedFilter>

                <SelectedFilter
                  options={siteFilterOption}
                  placeholder={"전체 현장"}
                  style={{
                    width: "200px",
                    height: "38px",
                    border: "1px solid #E2E8F0",
                  }}
                  onChange={(e) => {
                    setPage(1);
                    setSiteFilter(e.value);
                  }}
                ></SelectedFilter>
              </ButtonGroup>
            </div>
          </Panel>
        </Header>
        {/* <Panel>
          <div>
            <SearchBox
              placeholder="직원 검색"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                  if (
                    e.target.value.length > 0 &&
                    e.target.value.trim().length === 0
                  ) {
                    alert("직원을 입력해 주세요");
                    setSearchValue(e.target.value.trim());
                    return;
                  }
                  setSearchValue(e.target.value.trim());
                  setSearchTxt(e.target.value);
                }
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            ></SearchBox>
          </div>
          <div>
            <ButtonGroup>
              <Button
                style={{ backgroundColor: "#3279F5", color: "#fff" }}
                onClick={() => {
                  if (
                    checkRole(location.pathname, cookies.role, RoleType.WRITE)
                  ) {
                    setOpenedIdCheck(true);
                  }
                }}
              >
                <Icon src="/assets/icons/plus.svg" /> 신규등록
              </Button>
              <Button onClick={handleExcel}>
                <Icon src="/assets/icons/excel.svg" /> 엑셀파일
              </Button>
              <Button onClick={handleOpenPrintModal}>
                <Icon src="/assets/icons/printer.svg" /> 출력하기
              </Button>
              <SelectedFilter
                options={sortOptions}
                placeholder={"정렬"}
                iconName={"menu-02.svg"}
                style={{
                  width: "125px",
                  height: "38px",
                  border: "1px solid #E2E8F0",
                }}
                onChange={(e) => {
                  setPage(1);
                  setSort(e.value);
                }}
              ></SelectedFilter>

              <SelectedFilter
                options={filterOptions}
                placeholder={filterOptions[1].label}
                style={{
                  width: "160px",
                  height: "38px",
                  border: "1px solid #E2E8F0",
                }}
                onChange={(e) => {
                  setPage(1);
                  setFilter(e.value);
                }}
              ></SelectedFilter>

              <SelectedFilter
                options={siteFilterOption}
                placeholder={"전체 현장"}
                style={{
                  width: "200px",
                  height: "38px",
                  border: "1px solid #E2E8F0",
                }}
                onChange={(e) => {
                  setPage(1);
                  setSiteFilter(e.value);
                }}
              ></SelectedFilter>
            </ButtonGroup>
          </div>
        </Panel> */}
        {loading ? (
          <Loading></Loading>
        ) : (
          <>
            <TableContainer>
              <TableWrapper>
                <Table id="report-table">
                  <thead>
                    <tr>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center" }}
                        width={"50px"}
                      >
                        구분
                      </th>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center" }}
                        width={"250px"}
                        className="spaces"
                      >
                        이름
                      </th>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center", width: "80px" }}
                        className="spaces"
                      >
                        국적
                      </th>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center", width: "110px" }}
                      >
                        주민등록번호
                        <br />
                        (외국인관리번호)
                      </th>
                      <th rowSpan={2} style={{ textAlign: "center" }}>
                        나이
                      </th>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center" }}
                        className="spaces"
                      >
                        현장명
                      </th>
                      <th rowSpan={2} style={{ textAlign: "center" }}>
                        직종
                      </th>
                      <th rowSpan={2} style={{ textAlign: "center" }}>
                        역할
                      </th>
                      <th rowSpan={2} style={{ textAlign: "center" }}>
                        직급
                      </th>
                      <th rowSpan={2} style={{ textAlign: "center" }}>
                        소속
                      </th>
                      <th rowSpan={2} style={{ textAlign: "center" }}>
                        근무상태
                      </th>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center", width: "115px" }}
                      >
                        입사일
                      </th>
                      <th
                        rowSpan={2}
                        style={{ textAlign: "center", width: "115px" }}
                      >
                        퇴사일
                      </th>
                      <th colSpan={3} style={{ textAlign: "center" }}>
                        급여
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center", width: "70px" }}>
                        구분
                      </th>
                      <th style={{ textAlign: "center", width: "70px" }}>
                        임금
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees?.length === 0 ? (
                      <tr style={{ height: "500px" }}>
                        <td colSpan={15}>
                          <EmptyContainer
                            falseText={"등록된 근로자가"}
                            style={{ background: "#fff" }}
                          ></EmptyContainer>
                        </td>
                      </tr>
                    ) : (
                      employees.map((e, i) => (
                        <tr key={i}>
                          <td>{i + limit * (page - 1) + 1}</td>
                          <td>
                            <div
                              className="spaces"
                              style={{
                                minWidth: "250px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {e.user_nm}
                              <Button
                                className="remove"
                                onClick={() => {
                                  handleEmployInfo(e.user_site_seq);
                                  selectedList(e.work_field);
                                  setEmployWaiting(
                                    e.appr_type === "ER" ? true : false
                                  );
                                }}
                              >
                                상세보기
                              </Button>
                            </div>
                          </td>
                          <td
                            className="spaces"
                            style={{
                              minWidth: "80px",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {nations[e.nationality]}
                          </td>
                          <td
                            style={{ textAlign: "center", minWidth: "110px" }}
                          >
                            {e.id_num}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.id_num ? idNumToCurrentOld(e.id_num) : 0}세
                          </td>
                          <td className="spaces" style={{ minWidth: "200px" }}>
                            {e.site_nm}
                          </td>
                          <td>{jobPosition[e.job_position]}</td>
                          <td>{position[e.role_nm]}</td>
                          <td>{rank[e.rank_nm]}</td>
                          <td>{e.class_nm}</td>
                          <td>
                            {e.appr_type === "ER"
                              ? "입사승인대기"
                              : e.appr_type === "EY"
                              ? "근무중"
                              : e.appr_type === "XR"
                              ? "퇴사승인중"
                              : "퇴사"}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              minWidth: "115px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {e.join_dt &&
                              dayjs(e.join_dt).format("YYYY년 M월 D일")}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              minWidth: "115px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {e.leave_dt
                              ? dayjs(e.leave_dt).format("YYYY년 M월 D일")
                              : ""}
                          </td>
                          <td style={{ minWidth: "70px" }}>
                            {e.salary_type === "D"
                              ? "일급"
                              : e.salary_type === "M"
                              ? "월급"
                              : "본사 협의"}
                          </td>
                          <td
                            className={"table-right"}
                            style={{ minWidth: "70px" }}
                          >
                            {addCommas(e.salary_amt)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </TableWrapper>
            </TableContainer>
            <PagingFooter>
              <Pagination
                prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount === 0 ? 1 : totalCount}
                pageRangeDisplayed={5}
                hideFirstLastPages={true}
                onChange={(e) => setPage(e)}
              />

              <PagesList
                options={options}
                onChange={(e) => {
                  setLimit(e.value);
                  setPage(1);
                }}
              ></PagesList>
            </PagingFooter>
          </>
        )}

        {!selectedEmploy ? null : (
          <SideModal
            opend={opendDetailEmploy}
            closeModal={() => setOpendDetailEmploy(false)}
            header={"근로자 상세정보"}
            buttons={
              <>
                {employWaiting ? (
                  <ModalButton
                    onClick={() => {
                      if (
                        checkRole(
                          location.pathname,
                          cookies.role,
                          RoleType.WRITE
                        )
                      ) {
                        setOpendModifyEmploy(true);
                        selectEmploy = { ...selectedEmploy, loading: false };
                        handleEmployInfo(selectedEmploy?.user_site_seq);
                        setSelectLeavedDt(
                          moment(new Date()).add(1, "days").format("YYYY-MM-DD")
                        );
                        setSelectJoinedDt(
                          moment(new Date()).format("YYYY-MM-DD")
                        );
                        setLeaveRadioOption("Y");
                      }
                    }}
                  >
                    입사승인
                  </ModalButton>
                ) : (
                  <ModalButton
                    onClick={() => {
                      if (selectedEmploy.appr_type === "XY") {
                        alert("퇴사자는 수정할 수 없습니다.");
                        return;
                      }
                      if (
                        checkRole(
                          location.pathname,
                          cookies.role,
                          RoleType.WRITE
                        )
                      ) {
                        setOpendModifyEmploy(true);
                        selectEmploy = { ...selectedEmploy, loading: false };
                        handleEmployInfo(selectedEmploy?.user_site_seq);
                      }
                    }}
                  >
                    수정하기
                  </ModalButton>
                )}
              </>
            }
          >
            <Content>
              <figure>
                <h6>이름</h6>
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                    width: "calc(100% - 110px)",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedEmploy.user_nm}
                  <Button
                    style={{
                      backgroundColor: "#3279F5",
                      color: "#fff",
                      marginTop: "0",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => setOpendUserPayment(true)}
                  >
                    노무비
                  </Button>
                </h5>
              </figure>
              <figure>
                <h6>주민등록번호</h6>
                <h5>{selectedEmploy.id_num}</h5>
              </figure>
              <figure>
                <h6>나이</h6>
                <h5>
                  {selectedEmploy.id_num
                    ? +idNumToCurrentOld(selectedEmploy.id_num)
                    : 0}
                  세
                </h5>
              </figure>
              <figure>
                <h6>국적</h6>
                <h5>{nations[selectedEmploy.nationality]}</h5>
              </figure>
              <figure>
                <h6>체류자격</h6>
                <h5>{selectedEmploy.stay_cd}</h5>
              </figure>
              <figure>
                <h6>혈액형</h6>
                <h5>{selectedEmploy.blood_type}형</h5>
              </figure>
              <figure>
                <h6>현장명</h6>
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                    width: "calc(100% - 110px)",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedEmploy?.site_nm}
                  {employWaiting || (
                    <Button
                      style={{
                        backgroundColor: "#3279F5",
                        color: "#fff",
                        marginTop: "0",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() =>
                        handleLaborContract(selectedEmploy.user_site_seq)
                      }
                    >
                      근로계약서
                    </Button>
                  )}
                </h5>
              </figure>
              <figure>
                <h6>급여</h6>
                <h5>
                  {addCommas(selectedEmploy.salary_amt)} 원 /{" "}
                  {selectedEmploy.salary_nm === "일급" ? "일" : "월"}
                </h5>
              </figure>
              <figure>
                <h6>급여지급일</h6>
                <h5>
                  {selectedEmploy.salary_month_type} {selectedEmploy.salary_day}
                  일
                </h5>
              </figure>
              <figure>
                <h6>직종</h6>
                <h5>{jobPosition[selectedEmploy.job_position]}</h5>
              </figure>
              <figure>
                <h6>역할</h6>
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyItems: "center",
                    width: "calc(100% - 110px)",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedEmploy.position_nm}
                  {selectedEmploy.appr_type !== "XY" ? (
                    !employWaiting &&
                    (selectedEmploy.position_nm === "현장 소장" ||
                      selectedEmploy.position_nm === "현장 관리자") ? (
                      <Button
                        style={{
                          backgroundColor: "#3279F5",
                          color: "#fff",
                          marginTop: "0",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => setOpendRoleEmploy(true)}
                      >
                        앱 권한설정
                      </Button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </h5>
              </figure>
              <figure>
                <h6>직급</h6>
                <h5>{rank[selectedEmploy.rank_nm]}</h5>
              </figure>
              <figure>
                <h6>소속</h6>
                <h5>{selectedEmploy.class_nm}</h5>
              </figure>
              <figure>
                <h6>연락처</h6>
                <h5>{selectedEmploy.phone_nm}</h5>
              </figure>
              <figure>
                <h6>주소</h6>
                <h5
                  style={{
                    width: "60%",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {selectedEmploy.addr1 + " " + selectedEmploy.addr2}
                </h5>
              </figure>
              <figure>
                <h6>은행명/계좌번호</h6>
                <h5>
                  {selectedEmploy.bank_nm !== null &&
                  selectedEmploy.bank_acct !== null
                    ? selectedEmploy.bank_nm + " / " + selectedEmploy.bank_acct
                    : ""}
                </h5>
              </figure>
              <figure>
                <h6>예금주</h6>
                <h5>{selectedEmploy.bank_user_nm}</h5>
              </figure>
              {employWaiting || (
                <>
                  <figure>
                    <h6>입사일</h6>
                    <h5>
                      {selectedEmploy?.join_dt === null
                        ? ""
                        : dayjs(selectedEmploy?.join_dt).format(
                            "YYYY년 M월 D일"
                          )}
                    </h5>
                  </figure>
                  <figure>
                    <h6>
                      근로기간 <br />
                      (근로 종료일)
                    </h6>
                    <h5>
                      {selectedEmploy?.end_dt === null
                        ? "정하지않음"
                        : selectedEmploy?.end_dt}
                    </h5>
                  </figure>
                  <InputBox
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h6>퇴사일</h6>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                        width: "calc(100% - 110px)",
                        justifyContent: "space-between",
                      }}
                    >
                      {selectedEmploy.leave_dt === null ? (
                        <input
                          defaultValue={selectLeaveDt}
                          name="leave_day"
                          type={"date"}
                          id={"leave_day_input"}
                          style={{
                            margin: "0px",
                            width: "160px",
                            height: "34px",
                            fontSize: "14px",
                          }}
                          onChange={(e) => setSelectLeaveDt(e.target.value)}
                          onKeyDown={(e) => e.preventDefault()}
                          placeholder=""
                        />
                      ) : null}
                      {selectedEmploy.appr_type === "XY" ? (
                        selectedEmploy.leave_dt !== null ? (
                          <>
                            <h5
                              style={{
                                fontSize: "14px",
                                margin: "0px",
                              }}
                            >
                              {dayjs(selectedEmploy.leave_dt).format(
                                "YYYY년 M월 D일"
                              )}
                            </h5>
                            {selectedEmploy?.is_cancel_editable === "Y" && (
                              <Button
                                style={{
                                  backgroundColor: "#3279F5",
                                  color: "#fff",
                                  marginTop: "0",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() => {
                                  const confirmed =
                                    window.confirm(
                                      "정말로 퇴사취소하시겠습니까?"
                                    );
                                  if (confirmed) {
                                    const items = {
                                      site_seq: selectedEmploy.site_seq,
                                      user_seq: selectedEmploy.user_seq,
                                      user_site_seq:
                                        selectedEmploy.user_site_seq,
                                      leave_dt: selectedEmploy.leave_dt,
                                    };

                                    cancelLeaveMutate(items);
                                  }
                                }}
                              >
                                퇴사취소
                              </Button>
                            )}
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "#3279F5",
                            color: "#fff",
                          }}
                          onClick={() => handleLeaveJob(selectedEmploy)}
                        >
                          퇴사처리
                        </Button>
                      )}
                    </div>
                  </InputBox>
                  <hr />
                  <figure>
                    <h6>근태현황</h6>
                  </figure>
                </>
              )}
            </Content>
            {employWaiting || (
              <CalendarContainer>
                <AttendanceCalendar data={selectedEmploy} />
              </CalendarContainer>
            )}
          </SideModal>
        )}

        {/*신규 근로자 등록 모달 ///////////////////////////////////////////////////////////*/}
        <Modal
          opend={opendNewEmploy}
          closeModal={() => {
            setOpendNewEmploy(false);
            reset();
          }}
          header={
            <h4 style={{ margin: "0", fontSize: "18px" }}>
              근로자 {modalEmployYN ? "수정" : "등록"}
              {modalEmployYN && (
                <>
                  <br />
                  <em
                    style={{
                      fontSize: "14px",
                      color: "red",
                      fontWeight: "normal",
                      position: "absolute",
                      top: "48px",
                    }}
                  >
                    * 현재 화면에서 등록한 근로자는 앱사용이 제한됩니다.
                  </em>
                </>
              )}
            </h4>
          }
          okText={`${modalEmployYN ? "수정" : "등록"}`}
          handleOk={handleSubmit(handleNewEmploySubmit)}
          noCloseModal={true}
        >
          <>
            <FieldGroup>
              <Field style={{ width: "308px" }}>
                <h6>
                  이름 <span>*</span>
                </h6>
                <FieldInput
                  {...register("name")}
                  placeholder=""
                  onChange={(e) => {
                    if (e.target.value.length > 30) {
                      e.target.value = e.target.value.slice(0, 30);
                      return;
                    }
                    handleSetValue("name", e.target.value);
                  }}
                  readOnly={modalEmployYN}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  국적 <span>*</span>
                </h6>
                <Controller
                  name="nation"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      // placeholder={'공지 종류'}
                      style={{ width: "100%", height: "38px" }}
                      onChange={(e) => handleSetValue("nation", e.value)}
                      value={
                        getValues("nation") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("nation"),
                              label: nationOption.filter(
                                (e) => e.value === getValues("nation")
                              )[0]?.label,
                            }
                      }
                      options={nationOption}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  혈액형 <span>*</span>
                </h6>
                <Controller
                  name="bloodType"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      onChange={(e) => setValue("bloodType", e.value)}
                      value={
                        getValues("bloodType") === "I"
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("bloodType"),
                              label: bloodType.filter(
                                (e) => e.value === getValues("bloodType")
                              )[0]?.label,
                            }
                      }
                      options={bloodType}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6>
                  휴대전화 <span>*</span>
                </h6>
                <FieldInput
                  {...register("phone")}
                  onChange={(e) => {
                    if (e.target.value.length > 11) {
                      e.target.value = e.target.value.slice(0, 11);
                      return;
                    }
                    numberOnly(e);
                    handleSetValue("phone", e.target.value);
                  }}
                  placeholder=""
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  신분증 종류 <span>*</span>
                </h6>
                <Controller
                  name="idType"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      // placeholder={'공지 종류'}
                      style={{ width: "100%", height: "38px" }}
                      value={
                        getValues("idType") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("idType"),
                              label: idCardOptions.filter(
                                (e) => e.value === getValues("idType")
                              )[0]?.label,
                            }
                      }
                      onChange={(e) => {
                        if (e.value !== "ac") {
                          setValue("foreignId", "");
                        }
                        handleSetValue("idType", e.value);
                      }}
                      options={idCardOptions}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>
              <Field style={{ width: "308px" }}>
                <h6>
                  주민등록번호 <span>*</span>
                </h6>
                <FieldInput
                  {...register("idNum")}
                  onChange={(e) => {
                    if (e.target.value.length > 13) {
                      e.target.value = e.target.value.slice(0, 13);
                      return;
                    }
                    numberOnly(e);
                    handleSetValue("idNum", e.target.value);
                  }}
                  placeholder=""
                  readOnly={modalEmployYN}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  은행 <span>*</span>
                </h6>
                <Controller
                  name="bank"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      onChange={(e) => handleSetValue("bank", e.label)}
                      value={
                        getValues("bank") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("bank"),
                              label: getValues("bank"),
                            }
                      }
                      options={bankOption}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>
              <Field style={{ width: "308px" }}>
                <h6>
                  계좌번호 <span>*</span>
                </h6>
                <FieldInput
                  {...register("account")}
                  onChange={(e) => {
                    if (e.target.value.length > 20) {
                      e.target.value = e.target.value.slice(0, 20);
                      return;
                    }
                    numberOnly(e);
                    handleSetValue("account", e.target.value);
                  }}
                  placeholder=""
                />
              </Field>
              <Field style={{ width: "308px" }}>
                <h6>
                  예금주 <span>*</span>
                </h6>
                <FieldInput
                  {...register("accountOwner")}
                  placeholder=""
                  onChange={(e) => {
                    if (e.target.value.length > 30) {
                      e.target.value = e.target.value.slice(0, 30);
                      return;
                    }
                    handleSetValue("accountOwner", e.target.value);
                  }}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  예금주와의 관계 <span>*</span>
                </h6>
                <Controller
                  name="relate"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      value={
                        getValues("relate") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("relate"),
                              label: relateOptions.filter(
                                (e) => e.value === getValues("relate")
                              )[0]?.label,
                            }
                      }
                      onChange={(e) => handleSetValue("relate", e.value)}
                      options={relateOptions}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>
              {watch("idType") === "ac" ? (
                <Field style={{ width: "308px" }}>
                  <h6>
                    외국인 체류자격 (외국인 필수) <span>*</span>
                  </h6>
                  <FieldInput
                    {...register("foreignId")}
                    placeholder="외국인 체류자격 : ex) F-4"
                  />
                </Field>
              ) : null}
            </FieldGroup>

            <FieldGroup style={{ marginTop: "12px" }}>
              <div
                style={{
                  gridColumn: "1/3",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <Field style={{ flexGrow: 1 }}>
                  <h6>
                    주소 <span>*</span>
                  </h6>
                  <FieldInput
                    placeholder=""
                    disabled={true}
                    defaultValue={getValues("address")}
                    value={watch("address")}
                  />
                </Field>

                <Field style={{ width: "100px", marginTop: "12px" }}>
                  <SearchAddress
                    setAddrAndZoneCode={handleAddrAndZoneCode}
                    setLongitudeAndLatitude={setLongitudeAndLatitude}
                  />
                </Field>
              </div>
              <Field style={{ width: "308px" }}>
                <h6>
                  상세주소 <span>*</span>
                </h6>
                <FieldInput {...register("address2")} placeholder="" />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  현장 <span>*</span>
                </h6>
                <Controller
                  name="siteNew"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      placeholder={"선택"}
                      value={
                        getValues("siteNew") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("siteNew"),
                              label: siteOption.filter(
                                (e) => e.value === getValues("siteNew")
                              )[0]?.label,
                            }
                      }
                      options={siteOption}
                      onChange={(e) => {
                        handleSetValue("siteNew", e.value);
                        siteClassRequest(e.value);
                        selectedList(e.field);
                        //field.onChange(selectedOption?.value || ''); // siteNew 값 업데이트
                      }}
                    />
                  )}
                />
              </Field>
              <Field style={{ width: "308px" }}>
                <h6>
                  입사일 <span>*</span>
                </h6>
                <FieldInput
                  style={{ marginRight: "10px" }}
                  id={"leave_day_input"}
                  type={"date"}
                  onKeyDown={(e) => e.preventDefault()}
                  placeholder=""
                  {...register("joinDate")}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  급여 <span>*</span>
                </h6>
                <Controller
                  name="salaryType"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      placeholder={"선택"}
                      onChange={(e) => handleSetValue("salaryType", e.value)}
                      value={
                        getValues("salaryType") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : getValues("salaryType") === "D"
                          ? {
                              value: "D",
                              label: "일급",
                            }
                          : { value: "M", label: "월급" }
                      }
                      options={[
                        { value: "D", label: "일급" },
                        { value: "M", label: "월급" },
                      ]}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6>&nbsp;</h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: "12px",
                  }}
                >
                  <FieldInput
                    placeholder=""
                    style={{
                      width: "100%",
                      marginRight: "4px",
                      marginBottom: "0px",
                    }}
                    value={watch("salaryAmt")}
                    onChange={(e) => {
                      if (e.target.value.length > 14) {
                        e.target.value = e.target.value.slice(0, 15);
                        return;
                      }
                      numberOnly(e);
                      const commas = addCommas(parseInt(e.target.value));
                      handleSetValue("salaryAmt", commas);
                    }}
                  />
                  <span>원</span>
                </div>
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  직종 <span>*</span>
                </h6>
                <Controller
                  name="jobPosition"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      // placeholder={'공지 종류'}
                      style={{ width: "100%", height: "38px" }}
                      placeholder={"현장 선택해주세요"}
                      onChange={(e) => {
                        handleSetValue("jobPosition", e.value);
                      }}
                      value={
                        getValues("jobPosition") === ""
                          ? {
                              value: "",
                              label: "현장 선택해주세요",
                            }
                          : {
                              value: getValues("jobPosition"),
                              label: jobPositionOptions.filter(
                                (e) => e.value === getValues("jobPosition")
                              )[0]?.label,
                            }
                      }
                      options={jobPositionOptions}
                      disabled={
                        getValues("siteNew") === "" || watch("siteNew") === ""
                      }
                    ></SelectedFilterBox>
                  )}
                />
              </Field>
              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  역할 <span>*</span>
                </h6>
                <Controller
                  name="roleNew"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      // placeholder={'공지 종류'}
                      style={{ width: "100%", height: "38px" }}
                      placeholder="선택"
                      onChange={(e) => handleSetValue("roleNew", e.value)}
                      value={
                        getValues("roleNew") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("roleNew"),
                              label: roleOption.filter(
                                (e) => e.value === getValues("roleNew")
                              )[0]?.label,
                            }
                      }
                      options={roleOption}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "5px" }}>
                  직급 <span>*</span>
                </h6>
                <Controller
                  name="rankNew"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      // placeholder={'공지 종류'}
                      style={{ width: "100%", height: "38px" }}
                      placeholder="선택"
                      onChange={(e) => handleSetValue("rankNew", e.value)}
                      value={
                        getValues("rankNew") === ""
                          ? {
                              value: "",
                              label: "선택",
                            }
                          : {
                              value: getValues("rankNew"),
                              label: rankOptions.filter(
                                (e) => e.value === getValues("rankNew")
                              )[0]?.label,
                            }
                      }
                      options={rankOptions}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>
            </FieldGroup>

            <FieldGroup style={{ marginTop: "12px" }}>
              <Field style={{ width: "308px" }}>
                <h6>
                  소속 <span>*</span>
                </h6>
                <label
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    marginBottom: "6px",
                  }}
                >
                  <input
                    style={{ marginRight: "4px", alignItems: "center" }}
                    type="radio"
                    value="I"
                    checked={radioOption === "I"}
                    onChange={handleRadio}
                  />
                  <h6>회사소속</h6>
                </label>
                <Controller
                  name="class"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      // placeholder={'공지 종류'}
                      disabled={radioOption === "O" || watch("siteNew") === ""}
                      style={{ width: "100%", height: "38px" }}
                      placeholder="선택"
                      onChange={(e) => handleSetValue("class", e.value)}
                      value={
                        getValues("class") === ""
                          ? {
                              value: "",
                              label: "현장 선택해주세요",
                            }
                          : {
                              value: getValues("class"),
                              label: siteClassOptions.filter(
                                (e) => e.value === getValues("class")
                              )[0]?.label,
                            }
                      }
                      options={siteClassOptions}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6>&nbsp;</h6>
                <label
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    marginBottom: "6px",
                  }}
                >
                  <input
                    style={{ marginRight: "4px", alignItems: "center" }}
                    type="radio"
                    value="O"
                    checked={radioOption === "O"}
                    onChange={(e) => {
                      if (watch("siteNew") === "") {
                        alert("현장을 먼저 선택해주세요");
                        return;
                      }
                      setRadioOption(e.target.value);
                    }}
                  />
                  <h6>외부소속</h6>
                </label>
                <Controller
                  name="partnerType"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      disabled={radioOption === "I" || watch("siteNew") === ""}
                      placeholder="선택"
                      onChange={async (e) => {
                        handleSetValue("partnerType", e.value);
                        await partnerListRequest(watch("siteNew"), e.value);
                      }}
                      value={
                        !getValues("partnerType")
                          ? {
                              value: "",
                              label: "현장 선택해주세요",
                            }
                          : {
                              value: getValues("partnerType"),
                              label: partnerTypeOptions.filter(
                                (e) => e.value === getValues("partnerType")
                              )[0]?.label,
                            }
                      }
                      options={partnerTypeOptions}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>

              <Field style={{ width: "308px" }}>
                <h6 style={{ marginBottom: "37px" }}>&nbsp;</h6>

                <Controller
                  name="partner"
                  control={control}
                  render={({ field }) => (
                    <SelectedFilterBox
                      {...field}
                      style={{ width: "100%", height: "38px" }}
                      placeholder={"선택"}
                      onChange={async (e) => {
                        handleSetValue("partner", e.value);
                      }}
                      value={
                        getValues("partner") === ""
                          ? {
                              value: "",
                              label: "현장 선택해주세요",
                            }
                          : {
                              value: getValues("partner"),
                              label: partnerOptions.filter(
                                (e) => e.value === getValues("partner")
                              )[0]?.label,
                            }
                      }
                      options={partnerOptions}
                      disabled={radioOption === "I" || watch("siteNew") === ""}
                    ></SelectedFilterBox>
                  )}
                />
              </Field>
            </FieldGroup>

            <FieldGroup style={{ marginTop: "12px" }}>
              <Field style={{ width: "305px", zIndex: 0 }}>
                <h6>근로자 사진(선택)</h6>
                <Uploader>
                  <ImageBox>
                    <ImageUploader
                      onFileRemoved={() => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          handleImageRemove("labor_img");
                        }
                      }}
                      onFileAdded={(e) => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          handleImageUpload(e, "labor_img");
                        }
                      }}
                      deleteIcon={
                        watch("labor_img") ? (
                          <ImageDelete className="delete-label">
                            <ImageButton style={{ color: "#ff3838" }}>
                              <Icon src="/assets/icons/trash-04.svg" /> 삭제
                            </ImageButton>
                          </ImageDelete>
                        ) : (
                          <div></div>
                        )
                      }
                      uploadIcon={
                        !watch("labor_img") ? (
                          <>
                            <ImagePreview>
                              <img
                                src="/assets/icons/image-03.svg"
                                alt={"upload"}
                              />
                              <ImageButton>이미지 업로드</ImageButton>
                            </ImagePreview>
                            <br />
                          </>
                        ) : (
                          <>
                            <ImageWrapper className="upload-label">
                              <ImageButton>이미지 재업로드</ImageButton>
                            </ImageWrapper>
                          </>
                        )
                      }
                    />
                    <ImageDefault
                      src={watch("labor_img")}
                      style={
                        getValues("labor_img")
                          ? { width: "inherit" }
                          : { width: "unset" }
                      }
                    />
                    {watch("labor_img") && (
                      <div className="hover-wrapper"></div>
                    )}
                  </ImageBox>
                </Uploader>
              </Field>

              <Field style={{ width: "305px" }}>
                <h6>
                  신분증 사진 <span>*</span>
                </h6>
                <Uploader>
                  <ImageBox>
                    <ImageUploader
                      onFileRemoved={() => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          handleImageRemove("idcard_img");
                        }
                      }}
                      onFileAdded={(e) => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          handleImageUpload(e, "idcard_img");
                        }
                      }}
                      deleteIcon={
                        watch("idcard_img") ? (
                          <ImageDelete className="delete-label">
                            <ImageButton style={{ color: "#ff3838" }}>
                              <Icon src="/assets/icons/trash-04.svg" /> 삭제
                            </ImageButton>
                          </ImageDelete>
                        ) : (
                          <div></div>
                        )
                      }
                      uploadIcon={
                        !watch("idcard_img") ? (
                          <>
                            <ImagePreview>
                              <img
                                src="/assets/icons/image-03.svg"
                                alt={"upload"}
                              />
                              <ImageButton>이미지 업로드</ImageButton>
                            </ImagePreview>
                            <br />
                          </>
                        ) : (
                          <>
                            <ImageWrapper className="upload-label">
                              <ImageButton>이미지 재업로드</ImageButton>
                            </ImageWrapper>
                          </>
                        )
                      }
                    />
                    <ImageDefault
                      src={watch("idcard_img")}
                      style={
                        getValues("idcard_img")
                          ? { width: "inherit" }
                          : { width: "unset" }
                      }
                    />
                    {watch("idcard_img") && (
                      <div className="hover-wrapper"></div>
                    )}
                  </ImageBox>
                </Uploader>
              </Field>

              <Field style={{ width: "305px" }}>
                <h6>
                  통장사본 사진 <span>*</span>
                </h6>
                <Uploader>
                  <ImageBox>
                    <ImageUploader
                      onFileRemoved={() => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          handleImageRemove("bank_img");
                        }
                      }}
                      onFileAdded={(e) => {
                        if (
                          checkRole(
                            location.pathname,
                            cookies.role,
                            RoleType.WRITE
                          )
                        ) {
                          handleImageUpload(e, "bank_img");
                        }
                      }}
                      deleteIcon={
                        watch("bank_img") ? (
                          <ImageDelete className="delete-label">
                            <ImageButton style={{ color: "#ff3838" }}>
                              <Icon src="/assets/icons/trash-04.svg" /> 삭제
                            </ImageButton>
                          </ImageDelete>
                        ) : (
                          <div></div>
                        )
                      }
                      uploadIcon={
                        !watch("bank_img") ? (
                          <>
                            <ImagePreview>
                              <img
                                src="/assets/icons/image-03.svg"
                                alt={"upload"}
                              />
                              <ImageButton>이미지 업로드</ImageButton>
                            </ImagePreview>
                            <br />
                          </>
                        ) : (
                          <>
                            <ImageWrapper className="upload-label">
                              <ImageButton>이미지 재업로드</ImageButton>
                            </ImageWrapper>
                          </>
                        )
                      }
                    />
                    <ImageDefault
                      src={watch("bank_img")}
                      style={
                        getValues("bank_img")
                          ? { width: "inherit" }
                          : { width: "unset" }
                      }
                    />
                    {watch("bank_img") && <div className="hover-wrapper"></div>}
                  </ImageBox>
                </Uploader>
              </Field>
            </FieldGroup>

            <Field style={{ width: "308px" }}>
              <h6>&nbsp;</h6>
            </Field>
          </>
        </Modal>

        {/* 신규 등록 모달 끝 ////////////////////////////////////////////////////////////////////////////////*/}

        {!selectedEmploy ? null : (
          <Modal
            opend={opendModifyEmploy}
            closeModal={(event) => {
              const targetText = event.target.innerText;

              if (selectEmploy?.loading === true) {
                selectEmploy.loading = false;
                return;
              }
              if (targetText === "입사 거부") {
                // 입사 거부
                handleApplyJob("N");
              }
              setOpendModifyEmploy(false);
            }}
            header={employWaiting ? "입사승인" : "근로자 상세정보 수정"}
            okText={employWaiting ? "입사 승인" : "확인"}
            handleOk={async (event) => {
              const targetText = event.target.innerText;
              if (targetText === "입사 승인") {
                handleApplyJob("Y");
              } else {
                selectEmploy.loading = true;
                await modifyEmploy(selectEmploy);
              }
              return;
            }}
            cancelText={employWaiting ? "입사 거부" : "취소"}
            noCloseModal={true}
            widthCheck={"40%"}
          >
            <FieldGroup
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6,1fr)",
                gap: "18px 14px",
              }}
            >
              <Field style={{ gridColumn: "span 3" }}>
                <h6>이름</h6>
                <h5 style={{ margin: "5px 0 0 0" }}>
                  {selectedEmploy.user_nm}
                </h5>
              </Field>

              <Field style={{ gridColumn: "span 3" }}>
                <h6>주민번호</h6>
                <h5 style={{ margin: "5px 0 0 0" }}>{selectedEmploy.id_num}</h5>
              </Field>
              <Field fulled style={{ gridColumn: "span 6" }}>
                <h6>소속</h6>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    columnGap: "14px",
                  }}
                >
                  <div>
                    <label style={{ display: "flex", marginTop: "12px" }}>
                      <input
                        style={{ marginRight: "4px" }}
                        type="radio"
                        value="I"
                        checked={radioOption === "I"}
                        onChange={handleRadio}
                      />
                      <h6>회사소속</h6>
                    </label>

                    <Select
                      name="modal_support"
                      isDisabled={radioOption === "I" ? false : true}
                      styles={{
                        control: (base) => ({
                          ...base,
                          // width: '150px',
                          // marginRight: '20px',
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #E2E8F0",
                          },
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      placeholder={
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      }
                      options={siteClassOptions}
                      defaultValue={
                        selectedEmploy.class_seq === null
                          ? { label: "선택", value: "" }
                          : siteClassOptions.find(
                              (option) =>
                                selectedEmploy.class_seq == option.value
                            )
                      }
                      onChange={(e) => {
                        selectEmploy.class_seq = e.value;
                      }}
                    />
                  </div>
                  <div>
                    <label style={{ display: "flex", marginTop: "12px" }}>
                      <input
                        style={{ marginRight: "4px" }}
                        type="radio"
                        value="O"
                        checked={radioOption === "O"}
                        onChange={handleRadio}
                      />
                      <h6>외부소속</h6>
                    </label>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        columnGap: "14px",
                      }}
                    >
                      <Select
                        name="modal_support"
                        isDisabled={radioOption === "O" ? false : true}
                        styles={{
                          control: (base) => ({
                            ...base,
                            // width: 'calc(100% - 7px)',
                            // marginRight: '20px',
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #E2E8F0",
                            },
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          ></div>
                        }
                        options={partnerTypeOptions}
                        defaultValue={
                          selectedEmploy?.partner_type === null
                            ? {
                                label: "선택",
                                value: "",
                              }
                            : {
                                value:
                                  selectedEmploy.belong_type === "O"
                                    ? selectPartnerType
                                    : 0,
                                label: selectedEmploy?.partner_type_nm,
                              }
                        }
                        onChange={(e) => {
                          setSelectPartnerType(e.value);
                          selectEmploy.partner_type = e.value;
                          selectEmploy.partner_seq = "";
                          setSelectPartnerNm("");
                        }}
                      />

                      <Select
                        name="modal_support2"
                        isDisabled={radioOption === "O" ? false : true}
                        styles={{
                          control: (base) => ({
                            ...base,
                            // width: 'calc(100% - 7px)',
                            // marginRight: '20px',
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #E2E8F0",
                            },
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          ></div>
                        }
                        options={partnerOptions}
                        defaultValue={
                          selectedEmploy.partner_seq === null
                            ? { label: "선택", value: "" }
                            : partnerOptions.find(
                                (option) =>
                                  option.value == selectedEmploy.partner_seq
                              )
                        }
                        onChange={(e) => {
                          selectEmploy.partner_seq = e.value;
                          setSelectPartnerNm(e.label);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Field>
              <Field fulled style={{ gridColumn: "span 2" }}>
                <h6>직종</h6>
                <div>
                  <Select
                    name="modal_job_position"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100%",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #E2E8F0",
                        },
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      ></div>
                    }
                    options={jobPositionOptions}
                    defaultValue={{
                      value: selectedEmploy.job_position,
                      label: selectedEmploy.job_position_nm,
                    }}
                    onChange={(e) => {
                      selectEmploy.job_position = e.value;
                    }}
                  />
                </div>
              </Field>

              <Field fulled style={{ gridColumn: "span 2" }}>
                <h6>역할</h6>
                <div>
                  <Select
                    name="modal_position"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100%",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #E2E8F0",
                        },
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      ></div>
                    }
                    options={positionOptions}
                    defaultValue={{
                      value: selectedEmploy.role_nm,
                      label: selectedEmploy.position_nm,
                    }}
                    onChange={(e) => {
                      selectEmploy.role_nm = e.value;
                    }}
                  />
                </div>
              </Field>

              <Field fulled style={{ gridColumn: "span 2" }}>
                <h6>직급</h6>
                <div>
                  <Select
                    name="modal_rank"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100%",

                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #E2E8F0",
                        },
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    placeholder={
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      ></div>
                    }
                    options={rankOptions}
                    defaultValue={{
                      value: selectedEmploy.rank_nm,
                      label: rankOptions.find(
                        (e) => e.value === selectedEmploy.rank_nm
                      ).label,
                    }}
                    onChange={(e) => {
                      selectEmploy.rank_nm = e.value;
                    }}
                  />
                </div>
              </Field>
              <div
                style={{
                  gridColumn: "span 6",
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  columnGap: "14px",
                }}
                className="screen"
              >
                <Field fulled>
                  <h6>임금</h6>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(7,1fr)",
                      columnGap: "14px",
                    }}
                  >
                    <Select
                      name="modal_salary_type"
                      styles={{
                        control: (base) => ({
                          ...base,
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #E2E8F0",
                          },
                        }),
                        container: (base) => ({
                          ...base,
                          gridColumn: "span 3",
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      placeholder={
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      }
                      options={salaryOption}
                      value={{
                        value: selectedEmploy?.salary_type,
                        label: salaryOption?.find(
                          (item) => item.value === selectedEmploy?.salary_type
                        )?.label,
                      }}
                      onChange={(e) => {
                        if (selectedEmploy?.salary_type === e.value) return;
                        if (
                          selectEmploy.multiple_site_yn === "Y" &&
                          selectEmploy.salary_type !== e.value
                        ) {
                          const confirmed = window.confirm(
                            "임금방식 수정시 소속된 현장의 임금방식도 모두 변경됩니다.\n변경하시겠습니까?"
                          );
                          if (confirmed) {
                            setSelectedEmploy((prevState) => ({
                              ...prevState,
                              salary_type: e.value,
                            }));
                          }
                        } else {
                          setSelectedEmploy((prevState) => ({
                            ...prevState,
                            salary_type: e.value,
                          }));
                        }
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        gridColumn: "span 4",
                        alignItems: "center",
                        columnGap: "7px",
                      }}
                    >
                      <InputBox fulled>
                        {/*<h6>제목</h6>*/}
                        <input
                          name="modal_salary_amt"
                          type="text"
                          defaultValue={addCommas(selectedEmploy.salary_amt)}
                          onChange={(e) => {
                            if (e.target.value.length > 12) {
                              e.target.value = e.target.value.slice(0, 13);
                              return;
                            }
                            numberOnly(e);
                            e.target.value = stringNumberToInt(e.target?.value);
                            selectEmploy.salary_amt = e.target.value;
                            e.target.value = addCommas(e.target.value);
                          }}
                          style={{
                            margin: "5px 0px 0px 0px",
                            height: "38px",
                            fontSize: "14px",
                          }}
                        />
                      </InputBox>
                      <span>원</span>
                    </div>
                  </div>
                </Field>
                <Field fulled>
                  <h6>급여지급일</h6>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2,1fr)",
                      columnGap: "14px",
                    }}
                  >
                    <Select
                      name="month_salary_type"
                      styles={{
                        control: (base) => ({
                          ...base,
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #E2E8F0",
                          },
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      placeholder={
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      }
                      options={monthSalaryTypeOptions}
                      defaultValue={
                        employWaiting
                          ? monthSalaryTypeOptions[1]
                          : monthSalaryTypeOptions.filter(
                              (item) =>
                                item.value === selectEmploy?.salary_month_type
                            )
                      }
                      onChange={(e) => {
                        selectEmploy.salary_month_type = e.value;
                      }}
                    />

                    <Select
                      name="month_salary"
                      styles={{
                        control: (base) => ({
                          ...base,
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #E2E8F0",
                          },
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      placeholder={
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        ></div>
                      }
                      options={monthSalaryOptions}
                      defaultValue={
                        employWaiting
                          ? monthSalaryOptions[9]
                          : monthSalaryOptions.filter(
                              (item) => item.value === selectEmploy?.salary_day
                            )
                      }
                      onChange={(e) => {
                        selectEmploy.salary_day = e.value;
                      }}
                    />
                  </div>
                </Field>
                <span
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "14px",
                    whiteSpace: "break-spaces",
                    gridColumn: "span 2",
                  }}
                >
                  * 임금 변경은 전월 1일(전월 노무비 마감시 당월 1일)부터
                  적용되며 임금방식(ex&gt; 일급 -&gt; 월급) 변경이 포함되면
                  금일부터 적용됩니다.
                </span>
              </div>
              {employWaiting && (
                <Field fulled style={{ gridColumn: "span 3" }}>
                  <h6>입사일</h6>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <FieldInput
                      id={"joined_day"}
                      type={"date"}
                      style={{ fontSize: "14px" }}
                      onChange={(e) => {
                        setSelectJoinedDt(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={selectJoinedDt}
                      max={moment(new Date())
                        .add(1, "months")
                        .format("YYYY-MM-DD")}
                    />
                  </div>
                </Field>
              )}
              <Field fulled style={{ gridColumn: "span 3" }} className="span">
                <h6
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  근로기간(근로 종료일)
                  <div style={{ display: "flex", columnGap: "14px" }}>
                    <label style={{ display: "flex" }}>
                      <input
                        style={{ marginRight: "4px" }}
                        type="radio"
                        value="Y"
                        checked={leaveRadioOption === "Y"}
                        onChange={(event) =>
                          setLeaveRadioOption(event.target.value)
                        }
                      />
                      <h6>정함</h6>
                    </label>

                    <label style={{ display: "flex" }}>
                      <input
                        style={{ marginRight: "4px" }}
                        type="radio"
                        value="N"
                        checked={leaveRadioOption === "N"}
                        onChange={(event) =>
                          setLeaveRadioOption(event.target.value)
                        }
                      />
                      <h6>정하지않음</h6>
                    </label>
                  </div>
                </h6>
                {/* <div
                  style={{
                    display: "flex",
                    columnGap: "14px",
                    justifyContent: "flex-end",
                  }}
                >
                  <label style={{ display: "flex" }}>
                    <input
                      style={{ marginRight: "4px" }}
                      type="radio"
                      value="Y"
                      checked={leaveRadioOption === "Y"}
                      onChange={(event) =>
                        setLeaveRadioOption(event.target.value)
                      }
                    />
                    <h6>정함</h6>
                  </label>

                  <label style={{ display: "flex" }}>
                    <input
                      style={{ marginRight: "4px" }}
                      type="radio"
                      value="N"
                      checked={leaveRadioOption === "N"}
                      onChange={(event) =>
                        setLeaveRadioOption(event.target.value)
                      }
                    />
                    <h6>정하지않음</h6>
                  </label>
                </div> */}
                <div>
                  <FieldInput
                    id={"leaved_day"}
                    type={"date"}
                    onChange={(e) => {
                      setSelectLeavedDt(
                        moment(e.target.value).format("YYYY-MM-DD")
                      );
                    }}
                    defaultValue={selectLeavedDt}
                    style={
                      leaveRadioOption === "N"
                        ? {
                            gridColumn: "1/3",
                            fontSize: "14px",
                            opacity: "0",
                          }
                        : { gridColumn: "1/3", fontSize: "14px" }
                    }
                    disabled={leaveRadioOption === "N"}
                    min={moment(new Date()).add(1, "days").format("YYYY-MM-DD")}
                    placeholder=""
                  />
                </div>
              </Field>
            </FieldGroup>
          </Modal>
        )}

        <Modal
          opend={opendExport}
          closeModal={() => setOpendExport(false)}
          header={"출력 미리보기"}
          okText={"출력"}
          handleOk={() => {
            handlePrint();
          }}
          widthCheck={"70%"}
        >
          <LaborEmployPagePrint
            ref={componentRef}
            data={{
              employees,
              nations,
              jobPositon: jobPosition,
              position: position,
              rank: rank,
              company: companyNm,
            }}
          />
        </Modal>
        {/* 근로계약서 출력 팝업 */}

        {getLaborContractData && (
          <Modal
            opend={opendLaborContract}
            closeModal={() => {
              setOpendLaborContract(false);
            }}
            header={"근로계약서"}
            okText={"출력"}
            handleOk={(event) => {
              handlePrint2();
            }}
            widthCheck={"60%"}
            noCloseModal={true}
          >
            <LaborContractPagePrint
              ref={componentRef}
              data={getLaborContractData}
            />
            {getContractPrintData &&
            getContractPrintData?.contractBizType === "D" ? (
              <ContractBizStandardPrint
                ref={contractRef}
                data={getContractPrintData}
              ></ContractBizStandardPrint>
            ) : (
              <ContractBizConstructPrint
                ref={contractRef}
                data={getContractPrintData}
              ></ContractBizConstructPrint>
            )}
          </Modal>
        )}

        <Modal
          opend={openedIdCheck}
          closeModal={() => {
            setOpenedIdCheck(false);
            //  modalClose.current = false;
          }}
          header={
            <h4 style={{ margin: "0", fontSize: "18px" }}>
              근로자 등록 확인
              <>
                <br />
                <em
                  style={{
                    fontSize: "14px",
                    color: "red",
                    fontWeight: "normal",
                    position: "absolute",
                    top: "46px",
                  }}
                >
                  * 현재 화면에서 등록한 근로자는 앱사용이 제한됩니다.
                </em>
              </>
            </h4>
          }
          okText={"조회하기"}
          formRef={formRef}
          widthCheck={"500px"}
        >
          <EmployIdCheckForm
            ref={formRef}
            handleCheckId={handleCheckId}
          ></EmployIdCheckForm>
        </Modal>
        {/*  앱 권한 폼  */}
        <Modal
          opend={opendRoleEmploy}
          closeModal={() => setOpendRoleEmploy(false)}
          header={"앱 권한 설정"}
          okText={"확인"}
          formRef={formRef}
          //   handleOk={handleModifyEmploy}
          widthCheck={"500px"}
        >
          <RoleEmployForm
            ref={formRef}
            data={planDataData}
            employInfor={selectedEmploy}
            setOpendRoleEmploy={setOpendRoleEmploy}
            setOpendDetailEmploy={setOpendDetailEmploy}
            loadEmployees={loadEmployees}
          ></RoleEmployForm>
        </Modal>

        {/* 개인별 노무비 지급명세서 모달 */}

        <TableModal
          opend={opendUserPayment}
          closeModal={() => setOpendUserPayment(false)}
          header={`노무비 지급명세서`}
        >
          <UserPaymentStatement
            ref={userModal}
            data={selectedEmploy}
          ></UserPaymentStatement>
        </TableModal>
      </div>
    </AxiosNavigate>
  );
}

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 0 32px; */
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;
  padding: 12px;
  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media only screen and (max-width: 1327px) {
    margin-top: 8px;
  }
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;
  white-space: nowrap;
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  border-radius: 6px;
  background: #fff;
  margin-top: 5px;

  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    //padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
    box-sizing: border-box;
  }

  th {
    //background-color: #F7FAFC;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  .spaces {
    white-space: break-spaces;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;
const ImageBox = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px dashed #cbd5e0;
  overflow: hidden;

  > * {
    position: absolute;
  }

  .uploader__file,
  .delete-label,
  .upload-label {
    display: none;
  }

  .uploader__container {
    width: 100%;
    height: 100%;
  }

  &:hover {
    .hover-wrapper {
      background: rgba(0, 0, 0, 0.3);
    }

    .uploader__file,
    .delete-label,
    .upload-label {
      display: block;
    }
  }
`;
const Content = styled.div`
  figure {
    margin: 0;
    display: flex;
    align-items: center;
    min-height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 110px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  background: #fff;

  @media only screen and (min-width: 992px) {
    width: 376px;
  }
`;

const FieldGroup = styled.div`
  //display: flex;
  justify-content: left;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 14px;

  @media only screen and (max-width: 992px) {
    .screen {
      display: flex !important;
      flex-direction: column;
      row-gap: 18px;
    }
  }
`;

const Field = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;

    span {
      color: red;
    }
  }

  h5 {
    color: #171923;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 16px 0;
  }

  width: ${({ fulled }) => (fulled ? `100%` : "48%")};

  @media only screen and (max-width: 992px) {
    width: 100%;
    &&.span {
      grid-column: span 6 !important;
    }
    /* background-color: red; */
  }
`;

const FieldInput = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 8px 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  margin-bottom: 12px;
  margin-top: 6px;

  :focus {
  }

  &&:read-only {
    background-color: hsl(0, 0%, 95%);
  }
`;

const ImagePreview = styled.div`
  //display: flex;
  //padding: 80px 0;
  //flex-direction: column;
  //align-items: center;
  //gap: 48px;
  //flex: 1 0 0;
  //align-self: stretch;
  //border-radius: 6px;
  //border: 1px dashed #CBD5E0;
  //background: #FFF;
  //cursor: pointer;
  //margin: 12px 12px 0 12px;

  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  justify-content: center;
  flex: 1 0 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  align-self: stretch;

  background: #fff;
  //margin: 0 12px;
  cursor: pointer;
  // z-index: 999;

  img {
    top: 20%;
    position: absolute;
  }

  div {
    position: absolute;
    bottom: 20%;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  z-index: 999;
`;

const ImageDelete = styled.div`
  position: absolute;
  top: 50%;
  right: 10%;
  z-index: 999;
`;

const ImageButton = styled.div`
  color: #2d3748;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #cbd5e0;
  background: #fff;
  cursor: pointer;
  z-index: 999;
`;

const Uploader = styled.div`
  cursor: pointer;
  width: 100%;
  //  height: 100%;
  overflow: hidden;
  margin-top: 12px;

  .uploader__file,
  .delete-label,
  .upload-label {
    display: none;
  }

  position: relative;

  .hover-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .hover-wrapper {
      background: rgba(0, 0, 0, 0.3);
    }

    .delete-label,
    .upload-label {
      display: inline-block;
    }
  }

  //cursor: pointer;
  //
  //.hover-wrapper {
  //    width: 100%;
  //    height: 100%;
  //}
`;

const ImageDefault = styled.img`
  //width: 95%;
  //text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
`;

export default LaborEmploy;
